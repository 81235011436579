import React from "react"
import { FaLinkedin, FaEnvelope, FaGithub, FaFilePdf } from "react-icons/fa"

import AppLayout from "../Components/AppLayout"
import { Text, Box, Flex } from "../Components/Base"

const AboutPage = () => {
  return (
    <AppLayout>
      <Box width={"512px"} maxWidth={"100%"}>
        <Text fontSize={"4"} fontWeight={"semibold"} mb={2}>
          TADPOL RACHATASUMRIT
        </Text>
        <Text
          fontSize={"2"}
          fontWeight={"regular"}
          color={"greyscale.grey5"}
          mb={4}
        >
          Software Engineer
        </Text>

        <Text
          fontSize={"2"}
          fontWeight={"regular"}
          color={"greyscale.grey5"}
          mb={5}
        >
          I am a software engineer with the design background as a designer and
          architect. I enjoy both coding and design, but it is Better Together.
        </Text>

        {/* <Text
          fontSize={2}
          fontWeight={"regular"}
          color={"greyscale.grey5"}
          mb={3}
        >
          Contact:
        </Text> */}
        <Flex alignItems={"center"} mb={1}>
          <FaEnvelope size={16} />
          <Text fontSize={2} ml={2}>
            <a target={"_blank"} href={"mailto: trachata@andrew.cmu.edu"}>
              trachata@andrew.cmu.edu
            </a>
          </Text>
        </Flex>
        <Flex alignItems={"center"} mb={1}>
          <FaLinkedin size={16} />
          <Text fontSize={2} ml={2}>
            <a
              target={"_blank"}
              href={"https://www.linkedin.com/in/tadpolrach/"}
            >
              linkedin.com/in/tadpolrach
            </a>
          </Text>
        </Flex>
        <Flex alignItems={"center"} mb={1}>
          <FaGithub size={16} />
          <Text fontSize={2} ml={2}>
            <a target={"_blank"} href={"https://github.com/tadpolr"}>
              github.com/tadpolr
            </a>
          </Text>
        </Flex>
        <Flex alignItems={"center"}>
          <FaFilePdf size={16} />
          <Text fontSize={2} ml={2}>
            <a
              target={"_blank"}
              href={
                "https://drive.google.com/file/d/1THPXOXK6DcGo4ICRhucOIeW7F21b95NO/view?usp=sharing"
              }
            >
              Resume
            </a>
          </Text>
        </Flex>
      </Box>
    </AppLayout>
  )
}

export default AboutPage
